// Fonts

@import "mixins/font-face";

// Avenir
@include font-face("avenir-black", "Avenir-Black", normal);
@include font-face("avenir-medium", "Avenir-Medium", normal);
@include font-face("avenir-heavy", "Avenir-Heavy", normal);

// Halohandletter
@include font-face("halohandletter", "Halohandletter", normal);

// Museo
@include font-face("museo-700", "Museo-700", normal);

// Museo Slab
@include font-face("museo-slab-300", "Museo-Slab-300", normal);
@include font-face("museo-slab-500", "Museo-Slab-500", normal);
